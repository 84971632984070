/*
  Since we need to apply display block/flex on a custom element
  with tailwind, that styling overwrites hidden attribute. So we
  need something with higher specificity.
*/
pin-dialog[hidden] {
  display: none;
}

/*
  CoreDialog sets z-index, which is too low for what we need.
  We need to overwrite it to ensure that we don't have other
  elements in the way. Buuut origo-dialog will be hidden :'(((
*/
/* pin-dialog {
  z-index: 40;
} */

origo-notification.org-color-success {
  @apply bg-green-700 text-white;
}
