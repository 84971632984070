/* Used in e.g. banner header */
.pin-text-header-1 {
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
}

/* Used in e.g. dialog header */
.pin-text-header-2 {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
}

/* Used in e.g. below banner header-1 */
.pin-text-ingress {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

/* Used in labels for input*/
.pin-text-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* line-height: 19px; */
}

.pin-text-header-label {
  font-weight: 400;
  font-size: 14px;
  @apply text-gray-900;
}

.pin-text-title {
  text-transform: uppercase;
  font-weight: 700;
  @apply text-gray-900;
}

.pin-text-title--small {
  font-size: 12px;
}
