.pin-draggable {
  display: block;
  /* padding: 10px 5px; */
  /* margin-bottom: 10px; */
  /* border: solid 1px red; */
}

.pin-draggable:hover {
  cursor: grab;
}

.pin-droppable {
  margin: -11px 0;
  height: 22px;
  position: relative;
  z-index: 1;
}

.pin-droppable.pin-droppable-over {
  height: 50px;
  border: dashed 2px #cecece;
}

.pin-droppable.pin-droppable-over .pin-hover__child {
  height: 50px;
  border: dashed 2px #cecece;
}

[draggable] {
  touch-action: manipulation;
  user-select: none;
}
