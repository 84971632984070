/* COPIED FROM ORIGO */
.pin-table {
  border-collapse: separate;
  border-spacing: 0 1px;
  width: 100%;
}

.pin-table-header {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: inherit;
  padding-bottom: 5px;
  margin-bottom: 10px;
  @apply border-gray-500;
  @apply border-b;
}

.pin-table-row {
  display: flex;
  flex-flow: row;
  border-bottom: solid 1px #ccc;
}

.pin-table-cell {
  padding: 10px;
}
