.pin-search {
  @apply py-3 px-5 bg-blue-600 w-full max-w-xs rounded-full border border-blue-600 shadow-md font-semibold outline-none transition-all duration-300 cursor-pointer;
}

.pin-search:focus,
.pin-search:not(:placeholder-shown) {
  @apply shadow border-gray-400 shadow-inner bg-white max-w-none cursor-text;
}

.pin-search::placeholder {
  @apply text-white;
}
