.pin-status-pill {
  padding: 10px 20px;
  border-radius: 35px;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  border-width: 4px;
  @apply bg-yellow-300 text-black border-yellow-100;
}

.pin-status-pill--maskorama {
  background: #e2a9ea;
  color: #905b86;
  @apply border-purple-300;
}
