.pin-select {
  appearance: none;
  -webkit-appearance: none;
  background-image: linear-gradient(
      45deg,
      transparent 45%,
      currentcolor 46%,
      currentcolor 50%,
      transparent 52%
    ),
    linear-gradient(-45deg, transparent 45%, currentcolor 46%, currentcolor 50%, transparent 52%);
  background-size:
    0.36em 100%,
    0.36em 100%;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1.35em), calc(100% - 1em);
  background-position-y: 0;
  padding-right: 2.5em;
  vertical-align: top;
}
