pin-dialog {
  display: block;
  position: fixed;
  top: 3vh;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  /* padding: 3rem; */
}

pin-dialog .dialog__close {
  position: absolute;
  right: 2rem;
  top: 2rem;
}

pin-dialog + backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}

.pin-dialog__header {
  @apply px-4 py-2 bg-yellow-400;
}

.pin-dialog__body {
  @apply px-4 py-6 bg-gray-200;
}
.pin-dialog__footer {
  @apply flex justify-end px-4 py-2 shadow;
}

pin-dialog + backdrop[hidden] {
  pointer-events: none;
  visibility: hidden;
  display: block;
  opacity: 0;
}
