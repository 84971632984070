html {
  box-sizing: border-box;
  height: 100%;
  font-family: 'NRK Sans Variable', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  border-width: 0;
  border-style: solid;
}

body {
  margin: 0;
  min-height: 100%;
}

input,
textarea,
select,
button {
  font: inherit;
}

button:active {
  animation: pressed 700ms cubic-bezier(0, 0.25, 0.5, 0.75) backwards;
}

dd,
dl,
h1,
h2,
h3,
p {
  margin: 0;
}

fieldset,
ul {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

@keyframes pressed {
  from {
    transform: translateY(2px);
  }
}

/* Debug */
/* * { outline: 1px solid saddlebrown } */
