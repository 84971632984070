@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pin-loader-container {
  text-align: center;
  padding: 70px 0;
}

.pin-loader {
  margin: 50px 0;
  font-size: 40px;
  text-align: center;
  color: #000;
}

.pin-loader__inline svg,
.pin-loader svg {
  animation: spinner 2s infinite linear;
}

.pin-loader__inline {
  font-size: 0.8rem;
  /* Seems like the svg icon has spacing bottom which makes it hard to center stuff. This hopefully counters that effect*/
  padding-top: 3px;
}
