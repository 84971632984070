@import '@nrk/core-css/core-css.css';
@import '@nrk/nrk-sans/nrk-sans.css';

/* Elements */
@import 'tailwindcss/base.css';
@import 'base.css';

/* Components */
@import 'tailwindcss/components';
@import 'button.css';
@import 'text.css';
@import 'status-pill.css';
@import 'loader.css';
@import 'dialog.css';
@import 'table.css';
@import 'tabs.css';
@import 'drag-and-drop.css';
@import 'dropdown.css';
@import 'select.css';
@import './search.css';
@import './shame.css';

/* Utilities */
@import 'tailwindcss/utilities';
@import 'custom-utilities';
