.pin-button {
  @apply py-2 px-5 rounded font-semibold text-sm;
  cursor: pointer;
}

.pin-button--primary {
  @apply bg-blue-700 text-white;
}

.pin-button--secondary {
  @apply bg-gray-400 text-gray-800;
}

.pin-button--suggestive {
  @apply bg-transparent border-dashed border;
}

.pin-button--critical {
  @apply bg-red-700 text-white;
}

.pin-button--success {
  @apply bg-green-600 text-white;
}

.pin-button--gong {
  @apply bg-purple-700 text-white;
}

.pin-button--pause {
  @apply bg-yellow-300 text-black;
}

.pin-button--transparent {
  @apply bg-transparent text-gray-800;
}

.pin-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
