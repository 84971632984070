.pin-tabs {
  @apply bg-white w-full flex-grow-0 sticky top-0 z-20 border-b border-gray-300;
}

.pin-tab {
  @apply py-2 mr-5 border-solid border-b-4 border-white no-underline;
}

.pin-tab--is-active {
  @apply border-blue-600 no-underline;
}
